import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

const propTypes = {
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired
}

const CreateCareCenter = ({ organizations }) => {
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [departmentIds, setDepartmentIds] = useState('')
  const [organization, setOrganization] = useState('')
  const [providerGroupingOptions, setProviderGroupingOptions] = useState([])
  const [providerGrouping, setProviderGrouping] = useState('')

  const handleOrganizationChange = (event, value) => {
    const organizationId = value ? value.id : ''
    setOrganization(organizationId)

    if (organizationId) {
      $.ajax({
        method: 'GET',
        url: '/root/provider_grouping_finder',
        data: { organization_id: organizationId }
      })
      .done((response) => {
        setProviderGroupingOptions(Array.isArray(response.provider_groupings) ? response.provider_groupings : [])
      })
      .fail((error) => {
        flash.error(`Error fetching Provider Groupings. ${error.responseJSON.error}`, 5000)
        console.error(error)
      })
    } else {
      setProviderGroupingOptions([])
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    const formData = new FormData()
    formData.append("name", name)
    formData.append("phone_number", phoneNumber)
    formData.append("organization_id", organization)
    formData.append("department_ids", departmentIds)
    formData.append('provider_grouping_id', providerGrouping)

    $.ajax({
      method: 'POST',
      url: '/root/care_center/create',
      contentType: false,
      processData: false,
      data: formData,
    })
    .done(() => {
      flash.success('Care Center successfully Created', 50000)
    })
    .fail((error) => {
      flash.error(`Error creating your Care Center. ${error.responseJSON.error}`, 50000)
      console.error(error)
    })
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Name"
        value={name}
        onChange={event => setName(event.target.value)}
        required
        fullWidth
        InputProps={{disableUnderline: true}}
        variant="standard"
      />
      <TextField
        label="Phone Number"
        value={phoneNumber}
        onChange={event => setPhoneNumber(event.target.value)}
        required
        helperText={phoneNumber.replace(/\D/g, '').length !== 10 ? 'Phone number must be 10 digits' : ''}
        fullWidth
        InputProps={{disableUnderline: true}}
        variant="standard"
      />
      <Autocomplete
        id="organization-options"
        options={organizations}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Organization" InputProps={{...params.InputProps, disableUnderline: true}} variant="standard"/>}
        onChange={handleOrganizationChange}
      />
      <Autocomplete
        id="provider-group-options"
        options={providerGroupingOptions}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Provider Group" InputProps={{...params.InputProps, disableUnderline: true}} variant="standard"/>}
        onChange={(event, value) => setProviderGrouping(value ? value.id : '')}
      />
      <TextField
        label="Department Ids"
        value={departmentIds}
        onChange={event => setDepartmentIds(event.target.value)}
        required
        fullWidth
        InputProps={{disableUnderline: true}}
        variant="standard"
      />
      <button type="submit">Submit</button>
    </form>
  )
}

CreateCareCenter.propTypes = propTypes

export default CreateCareCenter