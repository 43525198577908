import React, { useState, useRef, useContext, useEffect } from 'react'
import { bool, func, number } from 'prop-types'
import { path } from 'ramda'
import Tag from './Tag'
import TagSelect from '../tags/TagSelect'
import EncounterTypeToggles from './EncounterTypeToggles'
import { Rule as RuleType, Tags, Styles } from '../athenaTypes'
import MigrationToggle from './MigrationToggle'
import { RuleContext } from './avhanaRulesList'

const propTypes = {
  rule: RuleType.isRequired,
  toggleRule: func.isRequired,
  toggleMigrationStatus: func.isRequired,
  tags: Tags.isRequired,
  style: Styles.isRequired,
  index: number.isRequired,
  isStaging: bool.isRequired,
  notProduction: bool.isRequired,
}

const styles = {
  collapsibleHeader: {
    lineHeight: '2em',
    padding: '1.5em 1em',
  },
  chipContainer: {
    paddingLeft: '0.5em',
  },
  chip: {
    backgroundColor: '#f2f2f2',
    fontSize: '12px',
  },
  ruleNameSpan: {
    paddingLeft: '1em',
    fontSize: '17px',
  },
  noPadding: { padding: '0' },
}

const Rule = ({ rule, style, tags, toggleRule, toggleMigrationStatus, index, isStaging, notProduction }) => {
  const [ruleTags, setRuleTags] = useState(rule.tags)
  const { setSize } = useContext(RuleContext)
  const header = useRef()
  const body = useRef()

  useEffect(() => {
    setRuleTags(rule.tags)
  }, [rule.tags])

  const handleClick = () => {
    setTimeout(() => {
      const headerHeight = header.current?.getBoundingClientRect().height
      const bodyHeight = body.current?.getBoundingClientRect().height
      const newHeight =
        bodyHeight > 100 ? headerHeight + bodyHeight + 20 : headerHeight
      setSize(index, newHeight)
    }, 300)
  }

  const tagHandler = (tag) => {
    setRuleTags((prevTags) => [...prevTags, tag])
    $.ajax({
      method: 'put',
      url: `/root/rules/${rule.id}/tags/${tag.id}/add`,
      data: {name: tag.name}
    }).fail((err) => {
      if (checkTimeout(err)) {
        redirectToLogin()
      } else {
        flash.error(
          'Error tagging your rule. Contact support@avhana.com if you need further assistance.'
        )
        console.error(err)
      }
    })
  }

  const removeTagHandler = (event, tag) => {
    $.ajax({
      method: 'put',
      url: `/root/rules/${rule.id}/tags/${tag.id}/remove`,
    })
      .done(() => {
        setRuleTags((prevTags) => prevTags.filter((t) => t.id !== tag.id))
      })
      .fail((err) => {
        if (checkTimeout(err)) {
          redirectToLogin()
        } else {
          flash.error(
            'Error removing the tag from your rule. Contact support@avhana.com if you need further assistance.'
          )
          console.error(err)
        }
      })
  }

  const doToggleRule = (event) => {
    toggleRule(rule.id, event.target.checked)
  }

  const showRuleTags = ruleTags.map((t) => (
    <Tag
      key={t.id}
      tag={t}
      removeTagHandler={removeTagHandler}
      styles={styles}
      notProduction={notProduction}
    />
  ))

  const editRuleLink = (
    <a href={`rules/${rule.id}/edit`}>
      <i className="material-icons left">mode_edit</i>
    </a>
  )

  let orderPreferenceDefaultLink
  const orders = path(['order_preference_reference', 'options', 'orders'], rule)
  if (orders) {
    orderPreferenceDefaultLink = (
      <div>
        <span className="bold-text">
          Default order:{' '}
          {orders[0] ? orders[0].order_name : 'No orders provided'}
        </span>
        <br />
        <a
          className="rule-link"
          href={`rules/${rule.id}/order_preference_references/${rule.order_preference_reference.id}/edit`}
          title="Editing does NOT update or change at the organization level"
        >
          Edit Default Order Preference
        </a>
      </div>
    )
  } else {
    orderPreferenceDefaultLink = (
      <a
        className="rule-link"
        href={`rules/${rule.id}/order_preference_references/new`}
      >
        Add Default Order Preference
      </a>
    )
  }
  return (
    <li
      className="collection-item avatar recommendation-card"
      style={{ ...style, listStyle: 'none' }}
    >
      <div
        className="collapsible-header rule-header"
        style={styles.collapsibleHeader}
        ref={header}
        onClick={handleClick}
      >
        <div className="container app-content">
          <div className="row">
            <div className="col s9">
              <div className="row">
                <div
                  className="col s12"
                  style={{ paddingLeft: '1em', lineHeight: '1.5em' }}
                >
                  <span className="bold-text">
                    {rule.default_display_code || rule.source}
                  </span>
                  <span style={styles.ruleNameSpan}>{rule.name}</span>
                </div>
                <div
                  className="col s12 tags-container"
                  style={styles.chipContainer}
                >
                  {showRuleTags}
                </div>
              </div>
            </div>
            <div className="col s3" style={{ paddingTop: '1em' }}>
              <div className="switch">
                <label htmlFor={`rule${rule.id}`}>
                  Off
                  <input
                    type="checkbox"
                    id={`rule${rule.id}`}
                    defaultChecked={rule.active}
                    onChange={doToggleRule}
                  />
                  <span className="lever" />
                  On
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="collapsible-body" ref={body}>
        <div
          className="row rule-display"
          style={{ padding: '1em 1em 2em 1em' }}
        >
          <div className="col s12">
            <div className="row" style={{ padding: '1em 0em 0em 0.8em' }}>
              <div className="col m2">{editRuleLink}</div>
              <div className="col m10">
                {notProduction && (
                <TagSelect
                  tags={tags}
                  ruleTags={ruleTags}
                  parentHandler={tagHandler}
                />
                )}
                <div className="row">
                  <div className="col s3">
                    <b>Action</b>
                  </div>
                  <div className="col s9">{rule.action}</div>
                </div>
                <div className="row">
                  <div className="col s3">
                    <b>Description</b>
                  </div>
                  <div className="col s9">{rule.description}</div>
                </div>
                <div className="row rule-option">
                  <div className="col s3">
                    <b>Rule UUID</b>
                  </div>
                  <div className="col s9">{rule.uuid}</div>
                </div>
                <EncounterTypeToggles rule={rule} />
                <div className="row rule-option">
                  <div className="col s12">{orderPreferenceDefaultLink}</div>
                </div>
                {isStaging && (
                  <MigrationToggle 
                    status={rule.migration_status} 
                    lastMigrated={rule.migrated_at}
                    onClick={() => toggleMigrationStatus(rule.id)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

Rule.propTypes = propTypes

export default Rule
