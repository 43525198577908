import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import RadioButtons from '../../reusableComponents/RadioButtons'

const BulkRuleSubscriptionStatus = ({ subscriptionIds, displayedRulesCount}) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState('')
  const [showConfirmation, setShowConfirmation] = useState(false)

  const handleOpenModal = () => {
    setShowConfirmation(false)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleSelectChange = (value) => {
    setSelectedStatus(value)
    setShowConfirmation(true)
  }

  const bulkUpdateSubscriptionStatus = async () => {
    try {
      $.ajax({
        method: 'put',
        url: '/subscriptions/update_many',
        data: { subscription_ids: subscriptionIds, active_silent: selectedStatus },
      }).done((data) => {
        setShowModal(false)
        flash.success(`${data.subscription_count} subscriptions have been updated to status: ${selectedStatus}`, 30000)
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      })
    } catch (error) {
      console.error(error)
      flash.error(
        'Error updating your subscriptions. Contact support@avhana.com if you need further assistance.'
      )
    }
  }

  const handleConfirmation = (confirm) => {
    if (confirm) {
      bulkUpdateSubscriptionStatus()
    }
    setShowConfirmation(false)
  }

  return (
    <div className="col s4 offset-s4">
      {/* Button to open the modal */}
      <Button variant="contained" color="primary" onClick={handleOpenModal}>
        Bulk update {displayedRulesCount} {pluralize('subscription', displayedRulesCount)}
      </Button>

      {/* Modal with select dropdown and confirmation */}
      {showModal && (
        <Dialog open={true} onClose={handleCloseModal}>
          <DialogTitle>Choose Subscription Status</DialogTitle>
          <DialogContent>
            {/* Select Dropdown */}
            <RadioButtons
              formId="active-silent"
              labels={[
                { name: 'Off', value: 'off' },
                { name: 'Active', value: 'active' },
                { name: 'Silent', value: 'silent' },
              ]}
              groupName={'Please select one:'}
              selected={selectedStatus}
              updateFormState={(status) => handleSelectChange(status.value)}
              onChange={handleSelectChange}
            />
            {showConfirmation && (
              <div style={{ marginTop: '20px' }}>
                <h5>
                  Are you sure you want to update <b>{displayedRulesCount}</b> rules to <b>{selectedStatus}</b>?
                </h5>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            {/* Confirmation Buttons */}
            {showConfirmation ? (
              <>
                <Button onClick={() => handleConfirmation(true)} color="primary">
                  Yes
                </Button>
                <Button onClick={() => handleConfirmation(false)} color="secondary">
                  No
                </Button>
              </>
            ) : (
              <Button onClick={handleCloseModal} color="secondary">
                Close
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}

BulkRuleSubscriptionStatus.propTypes = {
  subscriptionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  displayedRulesCount: PropTypes.number.isRequired,
}

export default BulkRuleSubscriptionStatus