import React, { useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import PropTypes from 'prop-types'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material'

const TagSelect = ({ parentHandler, ruleTags: initialRuleTags, tags }) => {
  const [selectedOption, setSelectedOption] = useState(null)
  const [ruleTags, setRuleTags] = useState(initialRuleTags)
  const [allTags] = useState(tags)
  const [inputValue, setInputValue] = useState('')
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [newTag, setNewTag] = useState('')

  const handleChange = (option, actionMeta) => {
    if (actionMeta.action === 'create-option') {
      setNewTag(option.label)
      setDialogOpen(true)
    } else {
      setSelectedOption(option)
      setRuleTags([...ruleTags, option])
      parentHandler({ id: option.value, name: option.label })
    }
  }

  const handleInputChange = (newInput) => {
    setInputValue(newInput)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
    setInputValue('')
  }

  const handleDialogConfirm = () => {
    const newTagObj = {
      id: null,
      name: newTag,
    }
    setRuleTags([...ruleTags, newTagObj])
    parentHandler({ id: newTagObj.id, name: newTagObj.name })
    setDialogOpen(false)
    setInputValue('')
  }

  const tagOptions = () => allTags.map((t) => ({
    value: t.id,
    label: t.name,
  }))

  return (
    <div style={{ padding: '0em 0em 1em 1em' }}>
      <CreatableSelect
        className="tagSelect"
        classNamePrefix="tagSelect"
        placeholder="Add a tag"
        value={selectedOption}
        onChange={handleChange}
        options={tagOptions()}
        onInputChange={handleInputChange}
        inputValue={inputValue}
      />
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tag "{newTag}" does not exist. Would you like to create it?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} variant="standard" color="primary" size="small">
            Cancel
          </Button>
          <Button onClick={handleDialogConfirm} variant="standard" color="primary" size="small">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const TAG = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
})

TagSelect.propTypes = {
  parentHandler: PropTypes.func.isRequired,
  ruleTags: PropTypes.arrayOf(TAG).isRequired,
  tags: PropTypes.arrayOf(TAG).isRequired,
}

export default TagSelect