import React, { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Tooltip from "@mui/material/Tooltip"
import PropTypes from 'prop-types'

const propTypes = {
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired
}

const CopyOrderPreferenceAndSubs = ({ organizations }) => {
  const [organization, setOrganization] = useState('')
  const [sourceOrganization, setSourceOrganization] = useState(null)
  const [targetOrganization, setTargetOrganization] = useState(null)
  const [sourceProviderGroupingOptions, setSourceProviderGroupingOptions] = useState([])
  const [targetProviderGroupingOptions, setTargetProviderGroupingOptions] = useState([])
  const [sourceProviderGrouping, setSourceProviderGrouping] = useState(null)
  const [targetProviderGrouping, setTargetProviderGrouping] = useState(null)

  useEffect(() => {
    if (sourceOrganization) {
      $.ajax({
        method: 'get',
        url: '/root/provider_grouping_finder',
        dataType: 'json',
        contentType: 'application/json',
        data: { organization_id: sourceOrganization.id },
      })
      .done((response) => {
        setSourceProviderGroupingOptions(Array.isArray(response.provider_groupings) ? response.provider_groupings : [])
      })
      .fail((error) => {
        flash.error(`Error fetching Provider Groupings. ${error.responseJSON.error}`, 5000)
        console.error(error)
      })
    }
  }, [sourceOrganization])

  useEffect(() => {
    if (targetOrganization) {
      $.ajax({
        method: 'get',
        url: '/root/provider_grouping_finder',
        dataType: 'json',
        contentType: 'application/json',
        data: { organization_id: targetOrganization.id },
      })
      .done((response) => {
        setTargetProviderGroupingOptions(Array.isArray(response.provider_groupings) ? response.provider_groupings : [])
      })
      .fail((error) => {
        flash.error(`Error fetching Provider Groupings. ${error.responseJSON.error}`, 5000)
        console.error(error)
      })
    }
  }, [targetOrganization])

  const handleSubmit = () => {
    $.ajax({
      method: 'POST',
      url: '/root/provider_grouping/copy_order_preferences_and_subscriptions',
      data: {
        source_provider_grouping_id: sourceProviderGrouping?.id,
        target_provider_grouping_id: targetProviderGrouping?.id,
        source_organization_id: sourceOrganization?.id,
        target_organization_id: targetOrganization?.id
      }
    })
    .done(() => {
        flash.success('Order Preferences and Subscriptions successfully copied', 5000)
    })
    .fail((error) => {
      flash.error(`Error copying Order Preferences and Subscriptions. ${error.responseJSON.error}`, 5000)
      console.error(error)
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Tooltip title="Source: Represents resource being to be copied over" arrow>
        <Autocomplete
          id="source-organization-options"
          options={organizations}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Source Organization" value={sourceOrganization?.name || ''} InputProps={{...params.InputProps, disableUnderline: true}} variant="standard"/>}
          onChange={(event, value) => setSourceOrganization(value)}
        />
      </Tooltip>
      <Tooltip title="Source: The provider group's order preferences to be copied from." arrow>
        <Autocomplete
          id="source-provider-grouping-options"
          options={sourceProviderGroupingOptions}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Source Provider Grouping" value={sourceProviderGrouping?.name || ''} InputProps={{...params.InputProps, disableUnderline: true}} variant="standard"/>}
          onChange={(event, value) => setSourceProviderGrouping(value)}
        />
      </Tooltip>
      <Tooltip title="Target: represents resource to be updated" arrow>
        <Autocomplete
          id="target-organization-options"
          options={organizations}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Target Organization" value={targetOrganization?.name || ''} InputProps={{...params.InputProps, disableUnderline: true}} variant="standard"/>}
          onChange={(event, value) => setTargetOrganization(value)}
        />
      </Tooltip>
      <Tooltip title="Target: The provider group's order preferences to be updated." arrow>
        <Autocomplete
          id="target-provider-grouping-options"
          options={targetProviderGroupingOptions}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Target Provider Grouping" value={targetProviderGrouping?.name || ''} InputProps={{...params.InputProps, disableUnderline: true}} variant="standard"/>}
          onChange={(event, value) => setTargetProviderGrouping(value)}
        />
      </Tooltip>
      <button type="submit">Submit</button>
    </form>
  )
}

CopyOrderPreferenceAndSubs.propTypes = propTypes

export default CopyOrderPreferenceAndSubs
