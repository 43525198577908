import React, { useState, useEffect } from 'react'
import { arrayOf, bool, shape, string } from 'prop-types'
import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { debounce } from 'lodash'
import AthenaOrderPrefTypeaheadBasic from '../../reusableComponents/AthenaOrderPrefTypeaheadBasic'

const propTypes = {
  orderPreferences: arrayOf(
    shape({
      name: string,
      order_type: string,
      ordertypeid: string,
    })
  ),
  orderSet: bool,
  selections: bool,
  targetValueSetName: string,
  formType: string,
}

const defaultProps = {
  orderPreferences: [],
  orderSet: false,
  selections: false,
  targetValueSetName: '',
  formType: ''
}

const switchAndAddRuleType = ({ orderPreferences: initialOrderPreferences, orderSet: initialOrderSet, selections, targetValueSetName, formType }) => {
  const [orderPreferences, setOrderPreferences] = useState(initialOrderPreferences)
  const [getSelections, setSelections] = useState(selections || false)
  const [orderSet, setOrderSet] = useState(initialOrderSet || false)
  const [options, setOptions] = useState([])
  const [value, setValue] = useState(targetValueSetName)

  useEffect(() => {
    const hiddenInput = document.querySelector('input[name="rule[target_value_set_name]"]')
    if (hiddenInput) {
      hiddenInput.value = value || ''
    }
  }, [value])

  const handleChange = debounce((event) => {
    const { value } = event.target
    if (value.length >= 3) {
      $.ajax({
        method: 'GET',
        url: '/root/value_sets_name_search',
        data: { query: value },
      })
        .done((response) => {
          setOptions(response)
        })
        .fail((error) => {
          console.error(error)
        })
    } else {
      setOptions([])
    }
  }, 300)

  const getOptionLabel = (option) => option

  const deleteOrderPreference = (index) => {
    const newOrderPreferences = orderPreferences.filter(
      (op, i) => i !== index
    )
    if (newOrderPreferences.length <= 1) setSelections(false)
    setOrderPreferences(newOrderPreferences)
  }

  const addOrderPreference = () => {
    if (!getSelections) setSelections(true)
    if (
      orderPreferences.length > 0 &&
      Object.keys(orderPreferences[orderPreferences.length - 1]).length !== 0
    ) {
      setOrderPreferences([...orderPreferences, {}])
    }
  }

  const toggleComboOrder = () => {
    const firstOrder = orderPreferences.slice(0, 1)
    if (orderSet) {
      setOrderPreferences(firstOrder)
    } else {
      setSelections(false)
      setOrderPreferences([...firstOrder, {}])
    }
    setOrderSet(!orderSet)
  }

  const optionSelected = ({ name, order_type, ordertypeid }, index) => {
    const newOrderPreference = {
      order_name: name,
      order_type_id: ordertypeid,
      order_type
    }
    const newOrderPreferences =
      orderPreferences.length > 0
        ? orderPreferences.map((o, i) => (i === index ? newOrderPreference : o))
        : [newOrderPreference]
    setOrderPreferences(newOrderPreferences)
  }

  return (
    <div>
      <div className="container">
        {orderPreferences.map((op, index) => (
          <React.Fragment key={op.order_name || "empty order preference"}>
            <AthenaOrderPrefTypeaheadBasic
              optionSelected={(option) => optionSelected(option, index)}
              column="12"
              orderName={op.order_name}
            />
            { !orderSet && (
              <button type="button" className="order-set-container" onClick={() => deleteOrderPreference(index)}>
                Remove
              </button>
            )}
            { orderSet && index === 0 && (
              <span>Combo Order</span>
            )}
          </React.Fragment>
        ))}

        {orderPreferences.length === 0 && (
          <AthenaOrderPrefTypeaheadBasic
            optionSelected={(option) => optionSelected(option, 0)}
            column="12"
          />
        )}

        <div>
          { !orderSet && orderPreferences.length < 4 && (
            <button type="button" onClick={() => addOrderPreference()}>
              Add Order Preference
            </button>
          )}
          { !orderSet && orderPreferences.length === 4 && (
            <span>Maximum of 4 Orders</span>
          )}
          <button type="button" onClick={() => toggleComboOrder()}>
            { orderSet ? "Revert to Single Order" : "Make Combo Order" }
          </button>
        </div>
        <input type="hidden" name="rule[order_preferences]" value={JSON.stringify(orderPreferences)} required />
        <input type="hidden" name="rule[order_set]" value={orderSet} required />
        <input type="hidden" name="rule[selections]" value={getSelections} required />
      </div>
      {(formType === 'guideline') && (
        <div className="container">
          <Autocomplete
            freeSolo
            id="tags-standard"
            options={options}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={handleChange}
                variant="standard"
                label="Accepted Value Set"
                fullWidth
                sx={{ mt: -4 }}
              />
            )}
            value={value}
            onChange={(_event, newValue) => setValue(newValue || null)}
          />
          <input type="hidden" name="rule[target_value_set_name]" value={value} />
        </div>
      )}
    </div>
  )
}

switchAndAddRuleType.propTypes = propTypes
switchAndAddRuleType.defaultProps = defaultProps

export default switchAndAddRuleType