import React from 'react'
import { func, bool } from 'prop-types'
import NativeListener from 'react-native-listener'

import { Styles, Tag as TagType } from '../athenaTypes'

const propTypes = {
  tag: TagType.isRequired,
  removeTagHandler: func.isRequired,
  styles: Styles.isRequired,
  notProduction: bool.isRequired,
}

const Tag = ({ tag, removeTagHandler, styles, notProduction }) => (
  <div key={tag.id} style={styles.chip} className="chip">
    {tag.name}
    {notProduction && (
      <NativeListener
        stopClick
        onClick={(e) => removeTagHandler(e, tag)}
      >
        <i className="close material-icons">
          close
        </i>
      </NativeListener>
    )}
  </div>
)

Tag.propTypes = propTypes

export default Tag
