import React, { useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

const MigrateTagsButton = () => {
  const [migrationCount, setMigrationCount] = useState(0)
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    const fetchMigrationCount = () => {
      $.ajax({
        method: 'get',
        url: '/root/tag_migration_count',
        dataType: 'json',
        contentType: 'application/json',
      })
        .done((response) => {
          setMigrationCount(response.tag_migration_count)
          setDisabled(response.tag_migration_count === 0)
        })
        .fail((error) => {
          console.error(error)
        })
    }

    fetchMigrationCount()
  }, [])

  const handleMigration = () => {
    setDisabled(true)
    $.ajax({
      method: 'post',
      url: '/root/tags/migrate_tags',
      dataType: 'json',
      contentType: 'application/json',
    })
      .done((response) => {
        console.log(response)
      })
      .fail((error) => {
        console.error(error)
      })
    
    setDialogOpen(false)
  }
  
  const handleButtonClick = () => {
    setDialogOpen(true)
  }

  const handleCancel = () => {
    setDialogOpen(false)
  }

  return (
    <>
      <div>
        <Button 
          className='migration-button' 
          disabled={disabled}
          onClick={handleButtonClick}
          variant="contained" 
          color="primary"
        >
          Migrate {migrationCount} Tags
        </Button>

        <Dialog open={isDialogOpen} onClose={handleCancel}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to migrate {migrationCount} tags to production?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleMigration} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}

export default MigrateTagsButton