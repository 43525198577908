import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DataGrid } from '@mui/x-data-grid'
import { Box, TextField, IconButton, Button, Tooltip, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material'
import MigrateTagsButton from './MigrateTagsButton'

const TagDialog = ({ notProduction, open, title, content, onClose, onConfirm, confirmText = 'Confirm' }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{content}</DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" size="small">Cancel</Button>
      <Button onClick={onConfirm} color="primary" size="small">{confirmText}</Button>
    </DialogActions>
  </Dialog>
)

const propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      rule_count: PropTypes.number.isRequired,
    })
  ).isRequired,
  notProduction: PropTypes.bool.isRequired,
}

const TagsDisplay = ({ tags, notProduction }) => {
  const [openDialog, setOpenDialog] = useState({ type: null, tag: null })
  const [tagName, setTagName] = useState('')

  const handleOpenDialog = (type, tag = null) => {
    setTagName(tag?.name || '')
    setOpenDialog({ type, tag })
  }

  const handleCloseDialog = () => setOpenDialog({ type: null, tag: null })

  const handleRequest = (method, data) => {
    $.ajax({ method, url: '/root/tags', data })
      .done((response) => {
        flash.success(response.message, 5000)
        handleCloseDialog()
        setTimeout(() => window.location.reload(), 1000)
      })
      .fail((error) => flash.error(`Error: ${error.responseJSON.error}`, 5000))
  }

  const handleCreateTag = () => handleRequest('post', { name: tagName })

  const handleEditTag = () => handleRequest('put', { id: openDialog.tag.id, new_name: tagName })

  const handleDeleteTag = () => handleRequest('put', { id: openDialog.tag.id, deleted: true })

  const columns = [
    { field: 'name', headerName: 'Tag Name', width: 500 },
    { field: 'rule_count', headerName: 'Rule Count', width: 400 },
    ...(notProduction ? [{
      field: 'actions',
      headerName: 'Actions',
      filterable: false,
      width: 200,
      renderCell: (params) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Edit Tag">
            <IconButton color="primary" onClick={() => handleOpenDialog('edit', params.row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Tag">
            <IconButton color="secondary" onClick={() => handleOpenDialog('delete', params.row)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    }] : [])
  ]

  return (
    <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      {notProduction &&
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 1, width: '100%' }}>
          <Button variant="contained" color="primary" onClick={() => handleOpenDialog('create')} sx={{ mb: 1 }}>
            Create Tag
          </Button>
          <MigrateTagsButton />
        </Box>
      }
      <Box sx={{ width: '100%' }}>
        <DataGrid rows={tags} columns={columns} getRowId={(row) => row.id} pageSize={5} autoHeight />
      </Box>

      {openDialog.type === 'create' && (
        <TagDialog 
          open={true} 
          title="Create New Tag" 
          content={<TextField autoFocus margin="dense" label="Tag Name" fullWidth value={tagName} onChange={(e) => setTagName(e.target.value)} sx={{ width: '500px' }} />} 
          onClose={handleCloseDialog} 
          onConfirm={handleCreateTag} 
          confirmText="Create" 
        />
      )}

      {openDialog.type === 'edit' && (
        <TagDialog 
          open={true} 
          title="Edit Tag" 
          content={<TextField autoFocus margin="dense" label="Tag Name" fullWidth value={tagName} onChange={(e) => setTagName(e.target.value)} />} 
          onClose={handleCloseDialog} 
          onConfirm={handleEditTag} 
          confirmText="Confirm" 
        />
      )}

      {openDialog.type === 'delete' && (
        <TagDialog 
          open={true} 
          title="Delete Tag" 
          content={<Typography>Are you sure you want to delete tag "{openDialog.tag?.name}"? It has {openDialog.tag?.rule_count} rules.</Typography>} 
          onClose={handleCloseDialog} 
          onConfirm={handleDeleteTag} 
          confirmText="Delete" 
        />
      )}
    </Box>
  )
}

TagsDisplay.propTypes = propTypes

export default TagsDisplay