import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { Autocomplete, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Chip, Box } from '@mui/material'
import PropTypes from 'prop-types'

const RuleFormTag = forwardRef(({ tagsOptions, initialRuleTags }, ref) => {
  const [ruleTags, setRuleTags] = useState(initialRuleTags || [])
  const [inputValue, setInputValue] = useState('')
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [newTag, setNewTag] = useState('')
  const [additions, setAdditions] = useState([])
  const [removals, setRemovals] = useState([])

  useImperativeHandle(ref, () => ({
    getTagsData: () => ({
      ruleTags,
      additions,
      removals,
    }),
  }))

  const handleChange = (event, value) => {
    const option = value[value.length - 1]
    if (option && option.inputValue) {
      setNewTag(option.inputValue)
      setDialogOpen(true)
    } else {
      setRuleTags(value)
      setAdditions(value.filter(tag => !initialRuleTags.some(t => t.id === tag.id)))
      setRemovals(initialRuleTags.filter(tag => !value.some(t => t.id === tag.id)))
    }
  }

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
    setInputValue('')
  }

  const handleDialogConfirm = () => {
    const newTagObj = { id: null, name: newTag }
    setRuleTags([...ruleTags, newTagObj])
    setAdditions([...additions, newTagObj])
    setDialogOpen(false)
    setInputValue('')
  }

  const handleTagDelete = (tag) => {
    setRuleTags(ruleTags.filter(t => t.id !== tag.id))
    if (initialRuleTags.some(t => t.id === tag.id)) {
      setRemovals([...removals, tag])
    } else {
      setAdditions(additions.filter(t => t.id !== tag.id))
    }
  }

  const tagOptions = () => tagsOptions
    .filter(option => !additions.some(addition => addition.id === option.id))
    .map((t) => ({ value: t.id, label: t.name, id: t.id, name: t.name }))

  return (
    <Box sx={{ padding: '1em' }}>
      <Autocomplete
        multiple
        freeSolo
        value={ruleTags}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        options={tagOptions()}
        getOptionLabel={(option) => option.name || option}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={option.id || option.name}
              label={option.name}
              {...getTagProps({ index })}
              onDelete={() => handleTagDelete(option)}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder="Add a tag"
            label="Tags"
          />
        )}
        filterOptions={(options, params) => {
          const filtered = options.filter(option => option.name.toLowerCase().includes(params.inputValue.toLowerCase()))
          if (params.inputValue !== '' && !filtered.some(option => option.name.toLowerCase() === params.inputValue.toLowerCase())) {
            filtered.push({
              inputValue: params.inputValue,
              name: `Create Tag "${params.inputValue}"`,
            })
          }
          return filtered
        }}
      />
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tag "{newTag}" does not exist. Would you like to create it?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} variant="contained" color="secondary" size="small">
            Cancel
          </Button>
          <Button onClick={handleDialogConfirm} variant="contained" color="primary" size="small">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <input type="hidden" name="rule[tags]" value={JSON.stringify({ tags: ruleTags, add_tags: additions, remove_tags: removals })} />
    </Box>
  )
})

const TAG = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
})

RuleFormTag.defaultProps = {
  initialRuleTags: [],
}

RuleFormTag.propTypes = {
  initialRuleTags: PropTypes.arrayOf(TAG),
  tagsOptions: PropTypes.arrayOf(TAG).isRequired,
}

export default RuleFormTag